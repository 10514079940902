<template>
<div>
	<h4 class="w-full text-center mt-4">{{getTextoIntroducao}}</h4>
	<vs-divider></vs-divider>
	<vs-row>
		<vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" v-if="isExibirLinksConcept"> 
			<vx-card>
				<div class="flex">
					<feather-icon icon="LayersIcon" svgClasses="w-6 h-6"/>
					<h6 class="w-full text-center"><a href="https://concepttecnologia.freshdesk.com/support/tickets"  target="_blank">Acompanhar Ticket   </a></h6>
				</div>
			</vx-card>
		</vs-col>		
		<vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" v-if="isExibirLinksConcept" style="padding-left: 10px"> 
			<vx-card>
				<div class="flex">
					<feather-icon icon="FilePlusIcon" svgClasses="w-6 h-6"/>
					<h6 class="w-full text-center"><a href="https://concepttecnologia.freshdesk.com/support/tickets/new"  target="_blank">Novo Ticket  </a></h6>
				</div>
			</vx-card>
		</vs-col>
		<vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" v-if="isExibirLinksConcept" style="padding-left: 10px"> 
			<vx-card>
				<div class="flex">
					<feather-icon icon="ArchiveIcon" svgClasses="w-6 h-6"/>
					<h6 class="w-full text-center"><a href="https://concepttecnologia.freshdesk.com/support/solutions"  target="_blank">FAQ e Documentações</a></h6>
				</div>
			</vx-card>
		</vs-col>
	</vs-row>
	<br/>
	<vs-row>
		<vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4"> 
			<vx-card>
				<div class="flex">
					<feather-icon icon="ActivityIcon" svgClasses="w-6 h-6"/>
					<h6 class="w-full text-center"><a href="https://stats.uptimerobot.com/NxBm8h0KQL" target="_blank">Status dos Servidores  </a></h6>
				</div>
			</vx-card>
		</vs-col>
		<vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" v-if="isExibirLinksConcept" style="padding-left: 10px"> 
			<vx-card>
				<div class="flex">
					<feather-icon icon="BookIcon" svgClasses="w-6 h-6"/>
					<h6 class="w-full text-center"><a href="http://blog.concept.inf.br/"  target="_blank">Blog</a></h6>
				</div>
			</vx-card>
		</vs-col>
		<vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" v-if="isExibirLinksConcept" style="padding-left: 10px"> 
			<vx-card>
				<div class="flex">
					<feather-icon icon="SendIcon" svgClasses="w-6 h-6"/>
					<h6 class="w-full text-center"><a href="https://api.whatsapp.com/send/?phone=558633011878&text=Ol%C3%A1&app_absent=1" target="_blank">Enviar Whatsapp  </a></h6>
				</div>
			</vx-card>
		</vs-col>
	</vs-row>
	<vs-divider>Status dos Veiculos</vs-divider>
	<vs-row>
		<vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6" v-if="isExibirLinksConcept" style="padding-right: 5px"> 
			<vx-card cardBackground="warning" @click="irParaRelatorio(24)">
				<div class="flex">
					<feather-icon icon="AlertTriangleIcon" svgClasses="w-6 h-6"/>
					<h6 class="w-full text-center" >
						{{dashboard.qtdVeiculosSemAtualizacao24horas}} veículos sem atualização <br/>há 24 horas
					</h6>
				</div>
			</vx-card>
		</vs-col>
		
		<vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6" v-if="isExibirLinksConcept" style="padding-left: 5px"> 
			<vx-card cardBackground="danger" @click="irParaRelatorio(dashboard.horasParaEnvioDesatualizacao)">
				<div class="flex">
					<feather-icon icon="AlertTriangleIcon" svgClasses="w-6 h-6"/>
					<h6 class="w-full text-center">{{dashboard.qtdVeiculosSemAtualizacaoParametroEmpresa}} veículos sem atualização 
						<br/>há {{dashboard.horasParaEnvioDesatualizacao}} horas
					</h6>
				</div>
			</vx-card>
		</vs-col>
	</vs-row>

	<vs-divider>Indicadores</vs-divider>
	<vs-row>
		<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6"> Ordens de Serviço </vs-col>
		<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6"> Módulos </vs-col>
	</vs-row>
	<vs-row>
		<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3"> 
			<vue-apex-charts type="polarArea" :options="graficoVeiculosPorStatus.chartOptions" :series="graficoVeiculosPorStatus.series" v-if="graficoVeiculosPorStatus"/>
		</vs-col>						
		<vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3"> 
			<ul v-if="graficoVeiculosPorStatus">
				<li v-for="itemGrafico in graficoVeiculosPorStatus.analyticsData" :key="itemGrafico.tipo" class="flex mb-3 justify-between" >
					<span class="flex items-center">
							<span class="inline-block h-5 w-5 rounded-full mr-2 border-4 border-solid" :style="`color:${itemGrafico.color}`"></span>
							<span class="font-semibold">{{ itemGrafico.tipo }}</span>
					</span>
					<span class="font-bold ml-6" :style="`color:${itemGrafico.color}`">{{ itemGrafico.qtd }}</span>
				</li>
			</ul>
		</vs-col>
		
		<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6"> 
			<ul v-if="graficoSumarioModulos">
				<li v-for="itemGrafico in graficoSumarioModulos.analyticsData" :key="itemGrafico.tipo" class="flex mb-3 justify-between" >
					<span class="flex items-center">
							<span class="inline-block h-5 w-5 rounded-full mr-2 border-4 border-solid" :style="`color:${itemGrafico.color}`"></span>
							<span class="font-semibold">{{ itemGrafico.tipo }}</span>
					</span>
					<span class="font-bold ml-6" :style="`color:${itemGrafico.color}`">{{ itemGrafico.qtd }} - {{ itemGrafico.percentual}}%</span>
				</li>
			</ul>
		</vs-col>
	</vs-row>
</div>
</template>

<script>
import axios from "@/axios.js"
import VueApexCharts from 'vue-apexcharts'
import generico from '@/generico.js'

export default {
	components: {
        VueApexCharts        
    },
	data() {
		return {
			dashboard: {agendadas: 0, abertas: 0, executadasNoMes: 0, canceladasNoMes: 0, pendentes: 0, qtdVeiculosSemAtualizacao24horas: 0, qtdVeiculosSemAtualizacaoParametroEmpresa:0, horasParaEnvioDesatualizacao:0},
			graficoVeiculosPorStatus: {
				analyticsData: [ ],
				series: [0,0,0,0,0],
				chartOptions: {
					labels: ['Agendadas', 'Abertas',  'Executadas', 'Canceladas', 'Pendentes'],
					yaxis: { show: false },
					legend: { show: false, position: 'bottom' },
					plotOptions: {
						polarArea: {                        
							offsetY: -5,
							track: {
								background: "#ebebeb",
								strokeWidth: '100%',
								margin: 15,
							},
						},
					}, 
					dataLabels: {
						enabled: true, show: true, name: {  fontSize: '16px', },
						formatter: function (val) {
							return generico.formatarNumero(val) + "%";
						},
					},            
					colors: ['#F8961E', '#577590', '#bc5090', '#ff6361', '#ffa600' ],
					fill: { type: 'solid', },
					stroke: { lineCap: 'round' },
					tooltip: { enabled: false },  
					chart: {                    
						dropShadow: { 
							enabled: true, blur: 3, left: 1, top: 1, opacity: 0.1
						},
					}
				}
			}, 
			graficoSumarioModulos: {
				analyticsData: [ ],
				series: [0,0,0,0,0,0,0,0,0,0,0,0,0],
				chartOptions: {
					labels: ['ATIVO', 'COM TECNICO',  'COM CLIENTE', 'DE TERCEIRO', 'EM ASSISTENCIA', 'EM ESTOQUE', 'ENVIAR P/ REPARO', 'INATIVO', 'PERDIDO ', 'QUEBRADO ',
					'QUEIMADO ', 'ROUBADO ',  'RESSARCIDO'],
					yaxis: { show: false },
					legend: { show: false, position: 'bottom' },
					plotOptions: {
						polarArea: {                        
							offsetY: -5,
							track: {
								background: "#ebebeb",
								strokeWidth: '100%',
								margin: 15,
							},
						},
					}, 
					dataLabels: {
						enabled: true, show: true, name: {  fontSize: '16px', },
						formatter: function (val) {
							return generico.formatarNumero(val) + "%";
						},
					},            
					colors: ['#F94144', '#F3722C', '#F8961E', '#F9844A', '#F9C74F', '#90BE6D', '#43AA8B', '#4D908E', '#577590', '#277DA1', '#50808E', '#DDD8C4', '#84B59F' ],
					fill: { type: 'solid', },
					stroke: { lineCap: 'round' },
					tooltip: { enabled: false },  
					chart: {                    
						dropShadow: { 
							enabled: true, blur: 3, left: 1, top: 1, opacity: 0.1
						},
					}
				}
			},
		}
	},  
	methods: {
		irParaRelatorio(horas){
			this.$router.push({
				name: "relatorios-relatorio-modulos-com-problema",
				params: {filtroHoras: String(horas) }

			})
		},
		fetchfDashboard() {     
			const params = new URLSearchParams();
			params.append('outputFormat', 'JSON');

			axios.post("/Dashboard", params, {              
					credentials: 'include',
					responseEncoding: 'iso88591',
					withCredentials: true } )
			.then((response) => {
				if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
					this.$router.push({ path: '/' });
				} else {
					this.dashboard = response.data;   
					
					let totalOS = this.dashboard.abertas + this.dashboard.executadasNoMes + this.dashboard.canceladasNoMes + this.dashboard.pendentes;
                    
					this.graficoVeiculosPorStatus.series = [];
					this.graficoVeiculosPorStatus.analyticsData = [];
										
					this.graficoVeiculosPorStatus.series.push(this.dashboard.abertas/totalOS*100);
					this.graficoVeiculosPorStatus.series.push(this.dashboard.executadasNoMes/totalOS*100);
					this.graficoVeiculosPorStatus.series.push(this.dashboard.canceladasNoMes/totalOS*100);
					this.graficoVeiculosPorStatus.series.push(this.dashboard.pendentes/totalOS*100);
					
					this.graficoVeiculosPorStatus.analyticsData.push({tipo: "Abertas", qtd: this.dashboard.abertas, color:  this.graficoVeiculosPorStatus.chartOptions.colors[0]});
					this.graficoVeiculosPorStatus.analyticsData.push({tipo: "Executadas No Mês", qtd: this.dashboard.executadasNoMes, color:  this.graficoVeiculosPorStatus.chartOptions.colors[1]});
					this.graficoVeiculosPorStatus.analyticsData.push({tipo: "Canceladas No Mês", qtd: this.dashboard.canceladasNoMes, color:  this.graficoVeiculosPorStatus.chartOptions.colors[2]});
					this.graficoVeiculosPorStatus.analyticsData.push({tipo: "Pendentes", qtd: this.dashboard.pendentes, color:  this.graficoVeiculosPorStatus.chartOptions.colors[3]});

					let qtdTotalModulos = this.dashboard.qtdTotalModulos;
                    
					this.graficoSumarioModulos.series = [];
					this.graficoSumarioModulos.analyticsData = [];

					for (var i=0; i < this.dashboard.sumarioModulo.length; i++) {
						this.graficoSumarioModulos.series.push(parseInt(this.dashboard.sumarioModulo[i].qtd)/qtdTotalModulos*100);
						this.graficoSumarioModulos.analyticsData.push({tipo: this.dashboard.sumarioModulo[i].nome, qtd: this.dashboard.sumarioModulo[i].qtd, percentual: generico.formatarNumero(parseInt(this.dashboard.sumarioModulo[i].qtd)/qtdTotalModulos*100), color:  this.graficoSumarioModulos.chartOptions.colors[i]});
					}                    
				}
			})
			.catch(() => { 
				this.$vs.loading.close();
			})    
		},
	},
	computed: {
		isExibirLinksConcept(){
			if (this.$store.state.AppActiveUser.idEmpresa === 39 && this.$store.state.AppActiveUser.perfil !== 'EMPRESA') {
				return false;
			} else {
				return true;
			}
			
		},
		getTextoIntroducao(){
			if (this.$store.state.AppActiveUser.idEmpresa === 39 && this.$store.state.AppActiveUser.perfil !== 'EMPRESA') {
				return "Bem vindo ao SETUP da plataforma de rastreamento.";
			} else {
				return "Bem vindo ao SETUP da plataforma de rastreamento da Concept Tecnologia";
			}
			
		}
	},
	mounted() {
		this.fetchfDashboard();
	},
}
</script>